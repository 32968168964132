import React, { Component, Fragment } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import isAuthenticated from '../common/utilities/authWrapper';
import Navbar from '../navbar/Navbar';
import Login from '../login/Login';
import NotFound from '../login/NotFound';
import LoadingPage from '../common/layout/LoadingPage';
import AuthDashboard from '../login/AuthDashboard';
import ModalManager from '../modals/ModalManager';

const Register1 = React.lazy(() => import('../login/Register1'));
const Register2 = React.lazy(() => import('../login/Register2'));
const Register3 = React.lazy(() => import('../login/Register3'));
const Dashboard = React.lazy(() => import('../../menus/dashboard/Index'));
const Profile = React.lazy(() => import('../../menus/profile/Index'));
const Presensi = React.lazy(() =>
  import('../../menus/_features/presensi/Index')
);
const Jadwal = React.lazy(() => import('../../menus/_features/jadwal/Index'));
const Pembelajaran = React.lazy(() =>
  import('../../menus/_features/pembelajaran/Index')
);
const MateriView = React.lazy(() =>
  import('../../menus/_features/pembelajaran/materi/ViewMateri')
);
const TugasView = React.lazy(() =>
  import('../../menus/_features/pembelajaran/tugas/ViewTugas')
);
const TugasSubmit = React.lazy(() =>
  import('../../menus/_features/pembelajaran/tugas/ViewTugasSubmit')
);
const LatihanView = React.lazy(() =>
  import('../../menus/_features/pembelajaran/latihan/ViewLatihan')
);
const LatihanResult = React.lazy(() =>
  import('../../menus/_features/pembelajaran/latihan/ViewLatihanResult')
);
const LatihanReview = React.lazy(() =>
  import('../../menus/_features/pembelajaran/latihan/ViewLatihanReview')
);
const Raporbulanan = React.lazy(() =>
  import('../../menus/_features/rapor-bulanan/Index')
);
const RaporbulananView = React.lazy(() =>
  import('../../menus/_features/rapor-bulanan/View')
);
const Raporsemester = React.lazy(() =>
  import('../../menus/_features/rapor-semester/Index')
);
const RaporsemesterView = React.lazy(() =>
  import('../../menus/_features/rapor-semester/View')
);
const Raporketuntasan = React.lazy(() =>
  import('../../menus/_features/rapor-ketuntasan/Index')
);
const RaporketuntasanView = React.lazy(() =>
  import('../../menus/_features/rapor-ketuntasan/View')
);
const Raporprojek = React.lazy(() =>
  import('../../menus/_features/rapor-projek/Index')
);
const RaporprojekView = React.lazy(() =>
  import('../../menus/_features/rapor-projek/View')
);
const Rapormid = React.lazy(() =>
  import('../../menus/_features/rapor-mid/Index')
);
const RapormidView = React.lazy(() =>
  import('../../menus/_features/rapor-mid/View')
);
const Announcement = React.lazy(() =>
  import('../../menus/_features/announcement/Index')
);
const Kalender = React.lazy(() =>
  import('../../menus/_features/kalender/Index')
);
const Pesan = React.lazy(() => import('../../menus/_features/pesan/Index'));
const Pengaturan = React.lazy(() =>
  import('../../menus/_features/pengaturan-akun/Index')
);

class App extends Component {
  render() {
    return (
      <Fragment>
        <ModalManager />
        <Fragment>
          <Navbar />
          <React.Suspense fallback={<LoadingPage />}>
            <Switch key={this.props.location.key}>
              <Route exact path='/' component={Login} />
              <Route exact path='/login' component={Login} />
              <Route exact path='/register' component={Register1} />
              <Route exact path='/register-2' component={Register2} />
              <Route exact path='/register-3' component={Register3} />
              <Route exact path='/authdashboard' component={AuthDashboard} />
              <Route
                exact
                path='/dashboard'
                component={isAuthenticated(Dashboard)}
              />
              <Route path='/profil' component={isAuthenticated(Profile)} />
              <Route path='/presensi' component={isAuthenticated(Presensi)} />
              <Route path='/jadwal' component={isAuthenticated(Jadwal)} />
              <Route
                exact
                path='/pembelajaran/materi/:id'
                component={isAuthenticated(MateriView)}
              />
              <Route
                exact
                path='/pembelajaran/tugas/:id'
                component={isAuthenticated(TugasView)}
              />
              <Route
                exact
                path='/pembelajaran/tugas/submit/:id'
                component={isAuthenticated(TugasSubmit)}
              />
              <Route
                exact
                path='/pembelajaran/latihan/:id'
                component={isAuthenticated(LatihanView)}
              />
              <Route
                exact
                path='/pembelajaran/latihan/result/:id'
                component={isAuthenticated(LatihanResult)}
              />
              <Route
                exact
                path='/pembelajaran/latihan/review/:id'
                component={isAuthenticated(LatihanReview)}
              />
              <Route
                path='/pembelajaran'
                component={isAuthenticated(Pembelajaran)}
              />
              <Route
                exact
                path='/rapor-bulanan'
                component={isAuthenticated(Raporbulanan)}
              />
              <Route
                exact
                path='/rapor-bulanan/:id'
                component={isAuthenticated(RaporbulananView)}
              />
              <Route
                exact
                path='/rapor-sem'
                component={isAuthenticated(Raporsemester)}
              />
              <Route
                exact
                path='/rapor-sem/:id'
                component={isAuthenticated(RaporsemesterView)}
              />
              <Route
                exact
                path='/rapor-ketuntasan'
                component={isAuthenticated(Raporketuntasan)}
              />
              <Route
                exact
                path='/rapor-ketuntasan/:id'
                component={isAuthenticated(RaporketuntasanView)}
              />
              <Route
                exact
                path='/rapor-projek'
                component={isAuthenticated(Raporprojek)}
              />
              <Route
                exact
                path='/rapor-projek/:id'
                component={isAuthenticated(RaporprojekView)}
              />
              <Route
                exact
                path='/rapor-mid'
                component={isAuthenticated(Rapormid)}
              />
              <Route
                exact
                path='/rapor-mid/:id'
                component={isAuthenticated(RapormidView)}
              />
              <Route
                exact
                path='/pengumuman'
                component={isAuthenticated(Announcement)}
              />
              <Route
                exact
                path='/kalender'
                component={isAuthenticated(Kalender)}
              />
              <Route exact path='/pesan' component={isAuthenticated(Pesan)} />
              <Route
                path='/pengaturan'
                component={isAuthenticated(Pengaturan)}
              />
              <Route path='/*' component={NotFound} />
            </Switch>
          </React.Suspense>
        </Fragment>
      </Fragment>
    );
  }
}

export default withRouter(App);
