import React from 'react';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { combineValidators, isRequired } from 'revalidate';
import { reduxForm, Field } from 'redux-form';
import CustomTextInput from '../common/form/CustomTextInput';
import { newLoginAuth } from './redux/authApi';
import LogoPicture from '../../images/logo.png';
import { SCHOOL_NAME } from '../common/data/siteConfig';
import { useUnamePass } from '../common/helpers/othersHelpers';
import LoadingButton from '../common/layout/LoadingButton';

function mapState(state) {
  return {
    auth: state.auth,
    loading: state.async.loading,
  };
}

const actions = {
  newLoginAuth,
};

const validate = combineValidators({
  username: isRequired({ message: 'Username is required' }),
  password: isRequired({ message: 'Password is required' }),
});

function Login(props) {
  const { newLoginAuth, handleSubmit, auth, error, loading } = props;
  let uPass = useUnamePass();

  const handleLogin = async (authData) => {
    await newLoginAuth(authData);
  };

  if (auth && auth.isAuth && !auth.registerParents)
    return <Redirect to='/dashboard' />;
  if (auth && auth.isAuth && auth.registerParents)
    return <Redirect to='/register-2' />;

  return (
    <>
      <section
        className='hero is-fullheight gradient-login'
        style={{ marginTop: -52 }}
      >
        {uPass && (
          <div
            className='hero-body'
            style={{
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: '#ffffff',
              height: '100%',
            }}
          >
            <LoadingButton />
          </div>
        )}
        {!uPass && (
          <>
            <div className='hero-body'>
              <div className='container'>
                <div className='columns'>
                  <div className='column is-one-third-tablet is-one-third-desktop is-one-third-widescreen is-one-third-fullhd is-hidden-mobile'></div>
                  <div className='column is-one-third-tablet is-one-third-desktop is-one-third-widescreen is-one-third-fullhd is-centered'>
                    <div className='box' style={{ minWidth: 342 }}>
                      <div className='media'>
                        <div className='media-content'>
                          <div className='content'>
                            <div className='has-text-centered mb-5'>
                              <img
                                alt='profile-pic'
                                src={LogoPicture}
                                style={{
                                  width: 120,
                                  height: 'auto',
                                }}
                              />
                              <p>
                                <span className='has-text-weight-bold is-size-4'>
                                  {SCHOOL_NAME}
                                </span>
                                <br />
                                Selamat Datang di Portal Sekolah
                              </p>
                            </div>
                            <form
                              onSubmit={handleSubmit(handleLogin)}
                              autoComplete='off'
                            >
                              <Field
                                name='username'
                                type='text'
                                component={CustomTextInput}
                                placeholder='Username'
                                autoComplete='new-password'
                                label='Username'
                                icon='account'
                              />
                              <Field
                                name='password'
                                type='password'
                                autoComplete='new-password'
                                component={CustomTextInput}
                                placeholder='Password'
                                label='Password'
                                icon='lock'
                              />
                              <div
                                className='field has-text-centered'
                                style={{ marginTop: 25 }}
                              >
                                {error && (
                                  <p className='help is-danger'>{error}</p>
                                )}
                                <button
                                  disabled={loading}
                                  type='submit'
                                  style={{ marginRight: 10 }}
                                  className='button is-info is-small is-rounded'
                                >
                                  <i
                                    className='mdi mdi-login is-size-6 icon'
                                    style={{ marginRight: 1 }}
                                  />{' '}
                                  {loading ? ` Processing` : ' Login'}
                                </button>
                              </div>
                            </form>
                            <div className='mt-5'>
                              <Link to='/register'>
                                <span className='flex justify-end align-center has-text-link is-size-7 has-text-right is-italic hand-pointer pr-2'>
                                  <i className='mdi mdi-account-multiple-plus is-size-6 icon' />
                                  Register Orang Tua/Wali
                                </span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='column is-one-third-tablet is-one-third-desktop is-one-third-widescreen is-one-third-fullhd is-hidden-mobile'></div>
                </div>
              </div>
            </div>
            <p
              className='has-text-centered is-size-7 has-text-dark'
              style={{ marginTop: -14, marginBottom: 8 }}
            >
              Copyright © {new Date().getFullYear()} Skoolas by{' '}
              <span className='has-text-weight-semibold'>Shaktavisi</span>
            </p>
          </>
        )}
      </section>
    </>
  );
}

export default withRouter(
  connect(mapState, actions)(reduxForm({ form: 'formLogin', validate })(Login))
);
