import { ANNOUNCEMENTS_GET } from './reduxConstant';
import { createReducer } from '../../../../App/common/utilities/reducerUtils';
import { differenceInDays, parseISO } from 'date-fns';

const initialState = { past: [], next: [] };

const announcementsGet = (state, payload) => {
  let announcements = payload.announcements;
  let past = [];
  let next = [];
  for (let i = 0; i < announcements.length; i++) {
    const el = announcements[i];
    let announcementDate = el.date && parseISO(el.date);
    let isBefore =
      differenceInDays(announcementDate, new Date()) < -30
        ? true
        : false;
    if (isBefore) {
      past.push(el);
    } else {
      next.push(el);
    }
  }
  return {
    ...state,
    past,
    next,
  };
};

export default createReducer(initialState, {
  [ANNOUNCEMENTS_GET]: announcementsGet,
});
