import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';
import menusReducer from '../common/reduxs/menu/menusReducer';
import authReducer from '../login/redux/authReducer';
import asyncReducer from '../common/reduxs/async/asyncReducer';
import swipeReducer from '../common/reduxs/swipe/reduxReducer';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import modalReducer from '../modals/redux/modalReducer';
import progressReducer from '../pages/progress/redux/progressReducer';
import detailsReducer from '../pages/details/redux/detailsReducer';
// Student Parents Portal
import userroleReducer from '../navbar/redux/reduxReducer';
import slideshowReducer from '../../menus/_features/slideshow/redux/reduxReducer';
import sprofilReducer from '../../menus/profile/redux/reduxReducer';
import spresensiReducer from '../../menus/_features/presensi/redux/reduxReducer';
import pembelajaranReducer from '../../menus/_features/pembelajaran/redux/reduxReducer';
import sjadwalReducer from '../../menus/_features/jadwal/redux/reduxReducer';
import sraporbulananReducer from '../../menus/_features/rapor-bulanan/redux/reduxReducer';
import sraporsemesterReducer from '../../menus/_features/rapor-semester/redux/reduxReducer';
import sraporketuntasanReducer from '../../menus/_features/rapor-ketuntasan/redux/reduxReducer';
import sraporprojekReducer from '../../menus/_features/rapor-projek/redux/reduxReducer';
import rapormidReducer from '../../menus/_features/rapor-mid/redux/reduxReducer';
import sraporsemReducer from '../../menus/_features/rapor-sem/redux/reduxReducer';
import saccountsReducer from '../../menus/_features/account-statement/redux/reduxReducer';
import announcementReducer from '../../menus/_features/announcement/redux/reduxReducer';
import skalenderReducer from '../../menus/_features/kalender/redux/reduxReducer';
import spesanReducer from '../../menus/_features/pesan/redux/reduxReducer';

const persistConfig = {
  key: 'root',
  storage: storageSession,
};

const appReducer = combineReducers({
  auth: authReducer,
  // Student Parents Portal
  slideshows: slideshowReducer,
  userrole: userroleReducer,
  sprofil: sprofilReducer,
  spresensi: spresensiReducer,
  pembelajaran: pembelajaranReducer,
  sjadwal: sjadwalReducer,
  sraporbulanan: sraporbulananReducer,
  sraporsemester: sraporsemesterReducer,
  sraporketuntasan: sraporketuntasanReducer,
  sraporprojek: sraporprojekReducer,
  rapormids: rapormidReducer,
  sraporsem: sraporsemReducer,
  saccounts: saccountsReducer,
  announcements: announcementReducer,
  skalender: skalenderReducer,
  spesan: spesanReducer,
  // End Student Parents Portal
  form: formReducer,
  menus: menusReducer,
  toastr: toastrReducer,
  async: asyncReducer,
  swipe: swipeReducer,
  modals: modalReducer,
  details: detailsReducer,
  progress: progressReducer,
});

const initialState = appReducer({}, {});

const rootReducer = (state, action) => {
  if (action.type === 'AUTH_LOGOUT') {
    state = initialState;
  }
  return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
