import React, { useEffect } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { newAuthDashboard } from './redux/authApi';
import { useUnamePass } from '../common/helpers/othersHelpers';
import LoadingButton from '../common/layout/LoadingButton';

function mapState(state) {
  return {
    auth: state.auth,
  };
}

const actions = {
  newAuthDashboard,
};

function AuthDashboard({ newAuthDashboard, auth }) {
  let history = useHistory();
  let uPass = useUnamePass();

  useEffect(() => {
    if (uPass && uPass.length > 0) {
      (function autologin() {
        let arrData = uPass && uPass.split('#&');
        let creds = {
          vusn: arrData[0],
          userId: arrData[1],
          deviceId: arrData[2],
        };
        newAuthDashboard(creds, history);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newAuthDashboard, uPass]);

  useEffect(() => {
    if (auth && auth.isAuth) {
      history.push('/dashboard');
    }
  }, [history, auth]);

  if (auth && auth.isAuth) return <Redirect to='/dashboard' />;
  if (auth && !auth.isAuth) return <Redirect to='/' />;

  return (
    <>
      <section
        className='hero is-fullheight'
        style={{ marginTop: -52, backgroundColor: '#ffffff' }}
      >
        <div className='hero-body'>
          <div
            className='container'
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <LoadingButton />
          </div>
        </div>
      </section>
    </>
  );
}

export default withRouter(connect(mapState, actions)(AuthDashboard));
