import {
  MATERIS_GET,
  TUGASS_GET,
  TUGAS_UPLOADSTATUS,
  LATIHANS_GET,
  TESS_GET,
  TINGKATS_GET,
  KELASS_GET,
  MATERI_GET,
  TUGAS_GET,
  LATIHAN_GET,
  TES_GET,
  LATIHAN_ANSWER_GET,
  STUDENT_ACTIVITY_GET,
} from './reduxConstant';

export const materisGet = (materis) => {
  return {
    type: MATERIS_GET,
    payload: {
      materis,
    },
  };
};

export const tugassGet = (tugass) => {
  return {
    type: TUGASS_GET,
    payload: {
      tugass,
    },
  };
};

export const tugasUploadStatus = (status) => {
  return {
    type: TUGAS_UPLOADSTATUS,
    payload: {
      status,
    },
  };
};

export const latihansGet = (latihans) => {
  return {
    type: LATIHANS_GET,
    payload: {
      latihans,
    },
  };
};

export const tessGet = (tess) => {
  return {
    type: TESS_GET,
    payload: {
      tess,
    },
  };
};

export const tingkatsGet = (tingkats) => {
  return {
    type: TINGKATS_GET,
    payload: {
      tingkats,
    },
  };
};

export const kelassGet = (kelass) => {
  return {
    type: KELASS_GET,
    payload: {
      kelass,
    },
  };
};

export const materiGet = (materi) => {
  return {
    type: MATERI_GET,
    payload: {
      materi,
    },
  };
};

export const tugasGet = (tugas) => {
  return {
    type: TUGAS_GET,
    payload: {
      tugas,
    },
  };
};

export const latihanGet = (latihan) => {
  return {
    type: LATIHAN_GET,
    payload: {
      latihan,
    },
  };
};

export const tesGet = (tes) => {
  return {
    type: TES_GET,
    payload: {
      tes,
    },
  };
};

export const latihanAnswerGet = (latihanAnswer) => {
  return {
    type: LATIHAN_ANSWER_GET,
    payload: {
      latihanAnswer,
    },
  };
};

export const studentActivityGet = (studentActivity) => {
  return {
    type: STUDENT_ACTIVITY_GET,
    payload: {
      studentActivity,
    },
  };
};
