import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_UPDATE, AUTH_ROLEGET } from './authConstant';
import { createReducer } from '../../common/utilities/reducerUtils';

const initialState = {
  isAuth: false,
  token: null,
  sessionId: null,
  vusn: null,
  userId: null,
  authorities: {},
  name: null,
  username: null,
  mainPhoto: null,
  expiresIn: null,
  role: {},
};

const authLogin = (state, payload) => {
  return { ...state, ...payload.auth };
};

const authLogout = (state) => {
  return {
    isAuth: false,
    token: null,
    userId: null,
    authorities: {},
    name: null,
    username: null,
    mainPhoto: null,
    expiresIn: null,
  };
};

const authUpdate = (state, payload) => {
  return {
    ...state,
    ...payload.auth,
  };
};

const authroleGet = (state, payload) => {
  return {
    ...state,
    role: payload.role
  }
}

export default createReducer(initialState, {
  [AUTH_LOGIN]: authLogin,
  [AUTH_LOGOUT]: authLogout,
  [AUTH_UPDATE]: authUpdate,
  [AUTH_ROLEGET]: authroleGet,
});
