import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_UPDATE, AUTH_ROLEGET } from "./authConstant";

export const authLogin = auth => {
  return {
    type: AUTH_LOGIN,
    payload: {
      auth
    }
  };
};

export const authLogout = () => {
  return {
    type: AUTH_LOGOUT
  };
};

export const authUpdate = auth => {
  return {
    type: AUTH_UPDATE,
    payload: {
      auth
    }
  };
};

export const authroleGet = role => {
  return {
    type: AUTH_ROLEGET,
    payload: {
      role
    }
  };
};
