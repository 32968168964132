import React, { Component } from 'react';
import Submenu from './Submenu';
import { Link } from 'react-router-dom';

class Menu extends Component {
  state = {
    openDropdown: false,
  };

  handleToggleSubmenu = () => () => {
    this.setState({
      openDropdown: !this.state.openDropdown,
    });
  };

  render() {
    const { pathname, dataMenu, dataAuth, profile } = this.props;
    const { openDropdown } = this.state;
    let url = dataMenu.alias;
    let authM = dataAuth.m;
    let authSubm = dataAuth.subm;
    const regex = new RegExp(url);
    const isActive = regex.test(pathname);
    const accessMenu = authM[dataMenu.id + '_view'];
    let rootPath = '/' + pathname.split('/').filter((e) => /\S/.test(e))[0];
    if (
      dataMenu.id === 'raporketuntasan' &&
      profile &&
      !profile.rapor_ketuntasan
    ) {
      return <></>;
    }
    if (
      dataMenu.id === 'raporprojek' &&
      profile &&
      !profile.rapor_projek
    ) {
      return <></>;
    }
    return (
      <>
        {accessMenu && (
          <li>
            {dataMenu.subm.length === 0 && (
              <Link
                to={dataMenu.url}
                className={
                  rootPath === dataMenu.url
                    ? `disable-select is-active custom-text-14 custom-text-overflow custom-hover-light-info`
                    : 'disable-select custom-text-14 custom-text-overflow custom-hover-light-info'
                }
              >
                <i className={`mdi mdi-${dataMenu.icon} is-size-6`} />{' '}
                {dataMenu.title}
              </Link>
            )}
            {dataMenu.subm.length > 0 && (
              <div
                onClick={this.handleToggleSubmenu('')}
                className={
                  isActive === true
                    ? `has-submenu disable-select has-background-white-ter custom-text-14 custom-text-overflow flex justify-between`
                    : 'has-submenu disable-select custom-text-14 custom-text-overflow flex justify-between'
                }
              >
                <span className='has-submenu'>
                  <i className={`mdi mdi-${dataMenu.icon} is-size-6`} />{' '}
                  {dataMenu.title}
                </span>{' '}
                <i
                  className={`is-size-6 mdi mdi-chevron-${
                    openDropdown ? 'up' : 'down'
                  }`}
                />
              </div>
            )}
            <ul className={openDropdown === true ? '' : 'is-hidden'}>
              {dataMenu.subm.map((subm) => (
                <Submenu
                  key={subm.id}
                  subm={subm}
                  authSubm={authSubm}
                  pathname={pathname}
                />
              ))}
            </ul>
          </li>
        )}
      </>
    );
  }
}

export default Menu;
