import { PESANS_GET } from './reduxConstant';
import { STATUS_UPDATE } from './reduxConstant';

export const pesansGet = (pesans) => {
  return {
    type: PESANS_GET,
    payload: {
      pesans,
    },
  };
};

export const statusUpdate = (pesan) => {
  return {
    type: STATUS_UPDATE,
    payload: {
      pesan,
    },
  };
};
