import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../App/common/reduxs/async/asyncActions';
import { ASYNC_ACTION_START } from '../../../../App/common/reduxs/async/asyncConstant';
import { SITE_ADDRESS } from '../../../../App/common/data/siteConfig';
import { checkRes, checkErr } from '../../../../App/common/helpers/checkRes';
import { authLogout } from '../../../../App/login/redux/authAction';
import { closeModal } from '../../../../App/modals/redux/modalActions';

export const updatePassword = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'updatePassword' });
    const vusn = auth.vusn;
    const userId = auth.userId;
    const sessionId = auth.sessionId ? auth.sessionId : '';
    const deviceId = auth.deviceId ? auth.deviceId : '';
    const formData = new FormData();
    formData.append('vusn', vusn);
    formData.append('userId', userId);
    formData.append('sessionId', sessionId);
    formData.append('deviceId', deviceId);
    formData.append('oldPass', values.oldPass);
    formData.append('newPass', values.newPass);
    try {
      let fetchData = await fetch(SITE_ADDRESS + 'password/', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const status = response.status;
      dispatch(asyncActionFinish());
      return status;
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const deleteAccount = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteAccount' });
    const vusn = auth.vusn;
    const userId = auth.userId;
    const sessionId = auth.sessionId ? auth.sessionId : '';
    const deviceId = auth.deviceId ? auth.deviceId : '';
    const formData = new FormData();
    formData.append('vusn', vusn);
    formData.append('userId', userId);
    formData.append('sessionId', sessionId);
    formData.append('deviceId', deviceId);
    try {
      let fetchData = await fetch(SITE_ADDRESS + 'delete-account/', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 201, history);
      if (resultCheck) {
        throw resultCheck;
      }
      history.push("/");
      dispatch(authLogout());
      dispatch(closeModal());
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};
