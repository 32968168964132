import {
  asyncActionFinish,
  asyncActionError,
} from '../../../../App/common/reduxs/async/asyncActions';
import {
  materisGet,
  tugassGet,
  tugasUploadStatus,
  latihansGet,
  tessGet,
  tingkatsGet,
  kelassGet,
  materiGet,
  tugasGet,
  latihanGet,
  tesGet,
  latihanAnswerGet,
} from './reduxAction';
import { ASYNC_ACTION_START } from '../../../../App/common/reduxs/async/asyncConstant';
import { SITE_ADDRESS_PEMBELAJARAN } from '../../../../App/common/data/siteConfig';
import { checkRes, checkErr } from '../../../../App/common/helpers/checkRes';
import { saveAs } from 'file-saver';

export const getMateris = (
  auth,
  history,
  tingkat,
  kelas,
  itemPerPage,
  currentPage,
  searchText
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getMateris' });
    let tingkatCode = tingkat && tingkat[0] && tingkat[0].code;
    let kelasCode;
    for (const item of kelas) {
      if (item.code === auth.active_kelas) {
        kelasCode = item.code;
        break;
      }
    }
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    formData.append('year', auth.active_year);
    formData.append('sem', auth.active_sem);
    formData.append('tingkat', tingkatCode);
    formData.append('kelas', kelasCode);
    formData.append('itemPerPage', itemPerPage);
    formData.append('currentPage', currentPage);
    formData.append('searchText', searchText);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/materi/',
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const items = response.materis;
      const total = response.totalMateri;
      dispatch(materisGet({ items, total }));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const getTugass = (
  auth,
  history,
  tingkat,
  kelas,
  itemPerPage,
  currentPage,
  searchText
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getTugass' });
    let tingkatCode = tingkat && tingkat[0] && tingkat[0].code;
    let kelasCode;
    for (const item of kelas) {
      if (item.code === auth.active_kelas) {
        kelasCode = item.code;
        break;
      }
    }
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    formData.append('year', auth.active_year);
    formData.append('sem', auth.active_sem);
    formData.append('tingkat', tingkatCode);
    formData.append('kelas', kelasCode);
    formData.append('itemPerPage', itemPerPage);
    formData.append('currentPage', currentPage);
    formData.append('searchText', searchText);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/tugas/',
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const items = response.tugass;
      const total = response.totalTugas;
      dispatch(tugassGet({ items, total }));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const getLatihans = (
  auth,
  history,
  tingkat,
  kelas,
  itemPerPage,
  currentPage,
  searchText
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getLatihans' });
    let tingkatCode = tingkat && tingkat[0] && tingkat[0].code;
    let kelasCode;
    for (const item of kelas) {
      if (item.code === auth.active_kelas) {
        kelasCode = item.code;
        break;
      }
    }
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    formData.append('year', auth.active_year);
    formData.append('sem', auth.active_sem);
    formData.append('tingkat', tingkatCode);
    formData.append('kelas', kelasCode);
    formData.append('itemPerPage', itemPerPage);
    formData.append('currentPage', currentPage);
    formData.append('searchText', searchText);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/latihan/',
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const items = response.latihans;
      const total = response.totalLatihan;
      dispatch(latihansGet({ items, total }));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const getTess = (
  auth,
  history,
  tingkat,
  kelas,
  itemPerPage,
  currentPage,
  searchText
) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getTess' });
    let tingkatCode = tingkat && tingkat[0] && tingkat[0].code;
    let kelasCode;
    for (const item of kelas) {
      if (item.code === auth.active_kelas) {
        kelasCode = item.code;
        break;
      }
    }
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    formData.append('year', auth.active_year);
    formData.append('sem', auth.active_sem);
    formData.append('tingkat', tingkatCode);
    formData.append('kelas', kelasCode);
    formData.append('itemPerPage', itemPerPage);
    formData.append('currentPage', currentPage);
    formData.append('searchText', searchText);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/tes/',
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const items = response.tess;
      const total = response.totalTes;
      dispatch(tessGet({ items, total }));
      dispatch(asyncActionFinish());
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const getTingkats = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getTingkats' });
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    formData.append('tingkat', auth.active_tingkat);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/tingkat/',
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const tingkats = response.tingkats;
      const kelass = response.kelass;
      dispatch(tingkatsGet(tingkats));
      dispatch(kelassGet(kelass));
      dispatch(asyncActionFinish());
      return 'finish';
    } catch (error) {
      checkErr(error);
      dispatch(asyncActionError());
    }
  };
};

export const getMateri = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getMateri' });
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/materi/' + id,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const materi = response.materi;
      dispatch(materiGet(materi));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getTugas = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getTugas' });
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/tugas/' + id,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const tugas = { ...response.tugas, submitted: response.submittedTugas };
      dispatch(tugasGet(tugas));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const uploadAttachment = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'uploadAttachment' });
    dispatch(tugasUploadStatus({ status: 'start' }));
    try {
      let tugasId = values.id;
      const remarks = {
        title: values.title,
        description: values.description,
        questions: values.questions,
      };
      const formData = new FormData();
      formData.append('token', auth.token);
      formData.append('userId', auth.userId);
      formData.append('username', auth.username);
      formData.append('id', values.submittedId);
      formData.append('no', 1); //Asking jeffri for update column => no absen
      formData.append('nis', auth.nis);
      formData.append('siswa', auth.username);
      formData.append('year', auth.active_year);
      formData.append('sem', auth.active_sem);
      formData.append('kelas', auth.active_kelas);
      formData.append('tingkat', auth.active_tingkat);
      formData.append('jenjang', auth.active_jenjang);
      formData.append('tugasId', values.id);
      formData.append('pelajaran', values.pelajaran);
      formData.append('tags', values.tags);
      formData.append('remarks', JSON.stringify(remarks));
      formData.append('file', values.file);
      formData.append('filename', values.filename);
      formData.append('filesize', values.filesize);
      formData.append('filetype', values.filetype);
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN +
          'pembelajaran/tugas/upload-attachment/' +
          tugasId,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 201, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const tugas = { ...response.tugas, submitted: response.submittedTugas };
      history.push('/pembelajaran/tugas/' + tugasId);
      dispatch(tugasGet(tugas));
      dispatch(tugasUploadStatus({ status: 'finish' }));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const deleteFile = (auth, history, values, file) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'deleteFile' });
    let submittedId = values.submitted.id;
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    formData.append('filelink', file.filelink);
    formData.append('id', submittedId);
    formData.append('tugasId', values.id);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN +
          'pembelajaran/tugas/delete-file/' +
          submittedId,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const tugas = { ...response.tugas, submitted: response.submittedTugas };
      history.push('/pembelajaran/tugas/' + tugas.id);
      dispatch(tugasGet(tugas));
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const submitTugas = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'submitTugas' });
    const remarks = {
      title: values.title,
      description: values.description,
      questions: values.questions,
      remarks: values.remarks,
    };
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    formData.append('username', auth.username);
    formData.append('id', values.submittedId);
    formData.append('no', 1); //Asking jeffri for update column => no absen
    formData.append('nis', auth.nis);
    formData.append('siswa', auth.username);
    formData.append('year', auth.active_year);
    formData.append('sem', auth.active_sem);
    formData.append('kelas', auth.active_kelas);
    formData.append('tingkat', auth.active_tingkat);
    formData.append('jenjang', auth.active_jenjang);
    formData.append('tugasId', values.id);
    formData.append('answers', JSON.stringify(values.answers));
    formData.append('time', values.time);
    formData.append('pelajaran', values.pelajaran);
    formData.append('tags', values.tags);
    formData.append('remarks', JSON.stringify(remarks));
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/tugas/submit/' + values.id,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const tugas = response.tugas;
      history.push({
        pathname: '/pembelajaran/tugas/submit/' + tugas.tugasId,
        state: { ...tugas, title: values.title },
      });
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getLatihan = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getLatihan' });
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/latihan/' + id,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const latihan = response.latihan;
      dispatch(latihanGet(latihan));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getLatihanAnswer = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getLatihanAnswer' });
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/latihan/answer/' + id,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const latihanAnswer = response.latihanAnswer;
      dispatch(latihanAnswerGet(latihanAnswer));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const getTes = (auth, history, id) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getTes' });
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/tes/' + id,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const tes = response.tes;
      dispatch(tesGet(tes));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const setReadActivity = (auth, history, id, title, content) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'setReadActivity' });
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('userId', auth.userId);
    formData.append('username', auth.vusn);
    formData.append('noabsen', auth.noabsen);
    formData.append('siswa', auth.username);
    formData.append('title', title);
    formData.append('content', content);
    formData.append('year', auth.active_year);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN + 'pembelajaran/set-read-activity/' + id,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const success = response.success;
      dispatch(asyncActionFinish());
      return success;
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const downloadFile = (auth, history, data, file) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'downloadFile' });
    const formData = new FormData();
    formData.append('userId', auth.userId);
    formData.append('username', auth.username);
    formData.append('filelink', file.filelink);
    try {
      let fetchData = await fetch(
        SITE_ADDRESS_PEMBELAJARAN +
          'pembelajaran/materi/download-file/' +
          data.id,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Bearer ' + auth.token,
          },
        }
      );
      const blob = await fetchData.blob();
      saveAs(
        blob,
        file.filelink.split('/')[file.filelink.split('/').length - 1]
      );
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};
