import { PESANS_GET, STATUS_UPDATE } from './reduxConstant';
import { createReducer } from '../../../../App/common/utilities/reducerUtils';

const initialState = [];

const pesansGet = (state, payload) => {
  let pesans = payload.pesans;
  let newPesans = [];
  if (pesans?.length > 0) {
    for (let i = 0; i < pesans.length; i++) {
      const el = pesans[i];
      let description = el?.description && JSON.parse(el.description);
      let file = el?.file ? JSON.parse(el?.file) : null;
      let newObj = {
        title: el?.title,
        messages: description?.messages,
        webmsgStatus: el?.webmsgStatus,
        messageId: el.messageId,
        file: file,
        createdAt: el.createdAt,
      };
      newPesans.push(newObj);
    }
  }
  return [...newPesans];
};

const statusUpdate = (state, payload) => {
  let pesan = payload?.pesan;
  let description = pesan?.description && JSON.parse(pesan?.description);
  let file = pesan?.file ? JSON.parse(pesan?.file) : null;
  let newPesan = {
    title: pesan?.title,
    messages: description?.messages,
    webmsgStatus: pesan?.webmsgStatus,
    messageId: pesan?.messageId,
    file: file,
    createdAt: pesan.createdAt,
  };
  return [
    ...state.filter(
      (pesan) => pesan.messageId + '' !== payload.pesan.messageId
    ),
    newPesan,
  ];
};

export default createReducer(initialState, {
  [PESANS_GET]: pesansGet,
  [STATUS_UPDATE]: statusUpdate,
});
