import {
  MATERIS_GET,
  TUGASS_GET,
  LATIHANS_GET,
  TESS_GET,
  TINGKATS_GET,
  KELASS_GET,
  MATERI_GET,
  TUGAS_GET,
  TUGAS_UPLOADSTATUS,
  LATIHAN_GET,
  TES_GET,
  LATIHAN_ANSWER_GET,
  STUDENT_ACTIVITY_GET,
} from './reduxConstant';
import { createReducer } from '../../../../App/common/utilities/reducerUtils';
import { sortArrayObject } from '../../../../App/common/helpers/objectHelpers';

const initialState = {
  total: { materi: 0, tugas: 0, latihan: 0, tes: 0 },
  search: { materi: '', tugas: '', latihan: '', tes: '' },
  materis: [],
  tugass: [],
  latihans: [],
  tess: [],
  tingkats: [],
  kelass: [],
  latihanAnswer: [],
  studentActivity: {
    materi: { read: [], unread: [] },
    tugas: { read: [], unread: [] },
    latihan: { read: [], unread: [] },
  },
  uploadStatus: 'finish',
};

const materisGet = (state, payload) => {
  let total = { ...state?.total, materi: payload?.materis?.total };
  let items = payload?.materis?.items;
  let sortItems = items && sortArrayObject(items, 'createdAt', 'desc');
  return {
    ...state,
    total: total,
    materis: [...sortItems],
  };
};

const tugassGet = (state, payload) => {
  let total = { ...state?.total, tugas: payload?.tugass?.total };
  return {
    ...state,
    total: total,
    tugass: [...payload?.tugass?.items],
  };
};

const latihansGet = (state, payload) => {
  let total = { ...state?.total, latihan: payload?.latihans?.total };
  return {
    ...state,
    total: total,
    latihans: [...payload?.latihans?.items],
  };
};

const tessGet = (state, payload) => {
  let total = { ...state?.total, tes: payload?.tess?.total };
  return {
    ...state,
    total: total,
    tess: [...payload?.tess?.items],
  };
};

const tingkatsGet = (state, payload) => {
  return {
    ...state,
    tingkats: [...payload?.tingkats],
  };
};

const kelassGet = (state, payload) => {
  return {
    ...state,
    kelass: [...payload?.kelass],
  };
};

const materiGet = (state, payload) => {
  let items = [
    ...state?.materis?.filter((item) => item.id !== payload?.materi?.id),
    payload?.materi,
  ];
  let sortItems = sortArrayObject(items, 'createdAt', 'desc');
  return {
    ...state,
    materis: [...sortItems],
  };
};

const tugasGet = (state, payload) => {
  let items = [
    ...state?.tugass?.filter((item) => item?.id !== payload?.tugas?.id),
    payload?.tugas,
  ];
  let sortItems = sortArrayObject(items, 'createdAt', 'desc');
  return {
    ...state,
    tugass: [...sortItems],
  };
};

const tugasUploadStatus = (state, payload) => {
  return {
    ...state,
    uploadStatus: payload.status,
  };
};

const latihanGet = (state, payload) => {
  let items = [
    ...state?.latihans?.filter((item) => item?.id !== payload?.latihan?.id),
    payload.latihan,
  ];
  let sortItems = sortArrayObject(items, 'createdAt', 'desc');
  return {
    ...state,
    latihans: [...sortItems],
  };
};

const tesGet = (state, payload) => {
  let items = [
    ...state?.tes?.filter((item) => item?.id !== payload?.tes?.id),
    payload?.tes,
  ];
  let sortItems = sortArrayObject(items, 'createdAt', 'desc');
  return {
    ...state,
    tess: [...sortItems],
  };
};

const latihanAnswerGet = (state, payload) => {
  let items;
  if (state?.latihanAnswer && state?.latihanAnswer[0]) {
    items = [
      ...state?.latihanAnswer?.filter(
        (item) => item?.id !== payload?.latihanAnswer?.id
      ),
      payload?.latihanAnswer,
    ];
  } else {
    items = [payload?.latihanAnswer];
  }
  return {
    ...state,
    latihanAnswer: [...items],
  };
};

const studentActivityGet = (state, payload) => {
  let materi = state?.materi;
  let tugas = state?.tugas;
  let latihan = state?.latihan;
  let materiActivity;
  let readMateriActivity = payload?.studentActivity?.materi;
  if (materi?.length > 0) {
    let readMateri = [];
    let unreadMateri = [];
    if (readMateriActivity?.length > 0) {
      for (const m of materi) {
        let statusRead = false;
        for (const rm of readMateriActivity) {
          if (m?.id === rm?.id) {
            statusRead = true;
            break;
          }
        }
        if (statusRead) {
          readMateri?.push(m);
        } else {
          unreadMateri?.push(m);
        }
      }
    } else {
      unreadMateri = [...materi];
    }
    materiActivity = {
      read: readMateri,
      unread: unreadMateri,
    };
  }
  let tugasActivity;
  let readTugasActivity = payload?.studentActivity?.tugas;
  if (tugas?.length > 0) {
    let readTugas = [];
    let unreadTugas = [];
    if (readTugasActivity?.length > 0) {
      for (const t of tugas) {
        let statusRead = false;
        for (const rt of readTugasActivity) {
          if (t?.id === rt?.id) {
            statusRead = true;
            break;
          }
        }
        if (statusRead) {
          readTugas?.push(t);
        } else {
          unreadTugas?.push(t);
        }
      }
    } else {
      unreadTugas = [...tugas];
    }
    tugasActivity = {
      read: readTugas,
      unread: unreadTugas,
    };
  }
  let latihanActivity;
  let readLatihanActivity = payload?.studentActivity?.latihan;
  if (latihan?.length > 0) {
    let readLatihan = [];
    let unreadLatihan = [];
    if (readLatihanActivity?.length > 0) {
      for (const l of latihan) {
        let statusRead = false;
        for (const rl of readLatihanActivity) {
          if (l?.id === rl?.id) {
            statusRead = true;
            break;
          }
        }
        if (statusRead) {
          readLatihan?.push(l);
        } else {
          unreadLatihan?.push(l);
        }
      }
    } else {
      unreadLatihan = [...latihan];
    }
    latihanActivity = {
      read: readLatihan,
      unread: unreadLatihan,
    };
  }
  return {
    ...state,
    studentActivity: {
      materi: materiActivity,
      tugas: tugasActivity,
      latihan: latihanActivity,
    },
  };
};

export default createReducer(initialState, {
  [MATERIS_GET]: materisGet,
  [TUGASS_GET]: tugassGet,
  [LATIHANS_GET]: latihansGet,
  [TESS_GET]: tessGet,
  [TINGKATS_GET]: tingkatsGet,
  [KELASS_GET]: kelassGet,
  [MATERI_GET]: materiGet,
  [TUGAS_GET]: tugasGet,
  [TUGAS_UPLOADSTATUS]: tugasUploadStatus,
  [LATIHAN_GET]: latihanGet,
  [TES_GET]: tesGet,
  [LATIHAN_ANSWER_GET]: latihanAnswerGet,
  [STUDENT_ACTIVITY_GET]: studentActivityGet,
});
