import {
  authLogin,
  authLogout,
  authUpdate,
  authroleGet
} from './authAction';
import { menusGet } from '../../common/reduxs/menu/menusAction';
import {
  asyncActionFinish,
  asyncActionError,
} from '../../common/reduxs/async/asyncActions';
import { ASYNC_ACTION_START } from '../../common/reduxs/async/asyncConstant';
import { toastr } from 'react-redux-toastr';
import { closeModal } from '../../modals/redux/modalActions';
import { SITE_ADDRESS } from '../../common/data/siteConfig';
import { authUpdating } from '../../common/utilities/checkAuth';
import { profilUpdate } from '../../../menus/profile/redux/reduxAction';
import { checkRes, checkErr } from '../../common/helpers/checkRes';
import { Menus } from '../../common/data/menus';
import { fetchWithTimeOut } from '../../common/helpers/othersHelpers';
const jwtDecode = require('jwt-decode');

export const newLoginAuth = (creds) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'newLoginAuth' });
    try {
      const formData = new FormData();
      const username = creds.username.trim();
      const password = creds.password.trim();
      formData.append('username', username);
      formData.append('password', password);
      let authUrl = 'auth/new-login';
      let fetchData = await fetchWithTimeOut(
        SITE_ADDRESS + authUrl,
        {
          method: 'POST',
          body: formData,
        },
        90
      );
      const response = await fetchData.json();
      if (fetchData.status !== 200) {
        const error = new Error(response.message);
        throw error;
      }
      const user = response.user;
      const sessionId = response.sessionId;
      const deviceId = response.deviceId;
      const FullMenus = [...Menus];
      const urlPhoto = response.urlPhoto;
      const authUpdated = authUpdating(user.role, FullMenus, user.userId);
      const expiresIn = jwtDecode(user.token);
      const setUser = {
        isAuth: true,
        token: user.token,
        sessionId: sessionId,
        deviceId: deviceId,
        vusn: creds.username,
        userId: user.userId,
        username: user.siswa,
        nis: user.nis,
        noabsen: user?.noabsen,
        active_year: user.active_year,
        active_sem: user.active_sem,
        active_kelas: user.active_kelas,
        active_tingkat: user.active_tingkat,
        active_jenjang: user.active_jenjang,
        authorities: authUpdated,
        mainPhoto: urlPhoto,
        expiresIn: expiresIn.exp * 1000,
      };
      dispatch(menusGet(Menus));
      dispatch(authLogin(setUser));
      dispatch(profilUpdate({ urlPhoto: urlPhoto }));
      dispatch(asyncActionFinish());
      return 'finish';
    } catch (err) {
      console.log(err);
      toastr.warning('Error', err.message);
      dispatch(asyncActionError());
    }
  };
};

export const newAuthDashboard = (creds, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'newAuthDashboard' });
    try {
      const formData = new FormData();
      formData.append('vusn', creds.vusn);
      formData.append('userId', creds.userId);
      formData.append('deviceId', creds.deviceId);
      let fetchData = await fetch(SITE_ADDRESS + 'auth/new-authdashboard', {
        method: 'POST',
        body: formData,
      });
      const response = await fetchData.json();
      if (fetchData.status !== 200) {
        const error = new Error(response.message);
        throw error;
      }
      const user = response.user;
      const sessionId = response.sessionId;
      const deviceId = response.deviceId;
      const urlPhoto = response.urlPhoto;
      const authUpdated = authUpdating(user.role, user.userId);
      const expiresIn = jwtDecode(user.token);
      const setUser = {
        isAuth: true,
        token: user.token,
        sessionId: sessionId,
        deviceId: deviceId,
        vusn: user.vusn,
        userId: user.userId,
        username: user.siswa,
        nis: user.nis,
        noabsen: user?.noabsen,
        active_year: user.active_year,
        active_sem: user.active_sem,
        active_kelas: user.active_kelas,
        active_tingkat: user.active_tingkat,
        active_jenjang: user.active_jenjang,
        authorities: authUpdated,
        mainPhoto: urlPhoto,
        expiresIn: expiresIn.exp * 1000,
      };
      dispatch(menusGet(Menus));
      dispatch(authLogin(setUser));
      dispatch(profilUpdate({ urlPhoto: urlPhoto }));
      dispatch(asyncActionFinish());
      return 'finish';
    } catch (error) {
      console.log(error);
      toastr.error('Error', error.message);
      dispatch(asyncActionError());
    }
  };
};
// Change Sibling Student Profile
export const updateAuth = (values, auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'updateAuth' });
    try {
      const vusn = auth.vusn;
      const userIdnew = values.userIdnew;
      const sessionId = auth.sessionId ? auth.sessionId : '';
      const deviceId = auth.deviceId ? auth.deviceId : '';
      const formData = new FormData();
      formData.append('vusn', vusn);
      formData.append('userId', auth.userId);
      formData.append('userIdnew', userIdnew);
      formData.append('usernameNew', values.username);
      formData.append('sessionId', sessionId);
      formData.append('deviceId', deviceId);
      let fetchData = await fetch(SITE_ADDRESS + 'auth/sibling-student', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      const username = values.username;
      const token = response.token;
      const urlPhoto = response.urlPhoto;
      const expiresIn = jwtDecode(token);
      const setUser = {
        userId: userIdnew,
        username: username,
        token: token,
        mainPhoto: urlPhoto,
        expiresIn: expiresIn.exp * 1000,
      };
      dispatch(authUpdate(setUser));
      dispatch(profilUpdate({ urlPhoto: urlPhoto }));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
    }
  };
};
// register parents accounts step 1 Login with student username & password
export const registerAuth = (history, creds) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'loginAuth' });
    try {
      const formData = new FormData();
      const username = creds.username.trim();
      const password = creds.password.trim();
      formData.append('username', username);
      formData.append('password', password);
      let authUrl = 'auth/new-login';
      let fetchData = await fetchWithTimeOut(
        SITE_ADDRESS + authUrl,
        {
          method: 'POST',
          body: formData,
        },
        90
      );
      const response = await fetchData.json();
      if (fetchData.status !== 200) {
        const error = new Error(response.message);
        throw error;
      }
      const user = response.user;
      const sessionId = response.sessionId;
      const deviceId = response.deviceId;
      const FullMenus = [...Menus];
      const urlPhoto = response.urlPhoto;
      const authUpdated = authUpdating(user.role, FullMenus, user.userId);
      const expiresIn = jwtDecode(user.token);
      const setUser = {
        isAuth: true,
        token: user.token,
        sessionId: sessionId,
        deviceId: deviceId,
        vusn: creds.username,
        userId: user.userId,
        username: user.siswa,
        nis: user.nis,
        active_year: user.active_year,
        active_sem: user.active_sem,
        active_kelas: user.active_kelas,
        active_tingkat: user.active_tingkat,
        active_jenjang: user.active_jenjang,
        authorities: authUpdated,
        mainPhoto: urlPhoto,
        expiresIn: expiresIn.exp * 1000,
        registerParents: true,
      };
      dispatch(menusGet(Menus));
      dispatch(authLogin(setUser));
      dispatch(profilUpdate({ urlPhoto: urlPhoto }));
      dispatch(asyncActionFinish());
      history.push('/register-2');
      return 'finish';
    } catch (err) {
      console.log(err);
      toastr.warning('Error', err.message);
      dispatch(asyncActionError());
    }
  };
};

export const getRoleAuth = (auth, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'getRoleAuth' });
    try {
      const vusn = auth.vusn;
      const userId = auth.userId;
      const sessionId = auth.sessionId ? auth.sessionId : '';
      const deviceId = auth.deviceId ? auth.deviceId : '';
      const formData = new FormData();
      formData.append('vusn', vusn);
      formData.append('userId', userId);
      formData.append('sessionId', sessionId);
      formData.append('deviceId', deviceId);
      let fetchData = await fetch(SITE_ADDRESS + 'auth/role-get', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      // OK,1,6518F843CC61B2C76E776339FFD8685E425168321000000252,1474,ALICIA VERONICA MODOK,content :
      // Role : Siswa
      // Grup/Sibling :
      // VALLERIE RIANA MODOK-userId=852;ALICIA VERONICA MODOK-userId=1474;
      // Detail Role :
      // ferdinanda.raubun@sekolahmakarios.id;ALICIA VERONICA MODOK;Ibu;

      // [{"status":[{"STATUS1":"OK","STATUS2":"1","SESSIONID":"C073D47364E608D191C4623CC543DECE16D27E761000000001","USERID":"570","NAMA":"Aaron Nicholas Otto","ROLE":"Siswa"}],"detail_role":[{"ISI":"Tidak Ada Role"}]}]

      // let uRole = {
      //   userrole: {
      //     role: 'Siswa',
      //     group: [
      //       {
      //         userId: '852',
      //         name: 'VALLERIE RIANA MODOK',
      //       },
      //       {
      //         userId: '1474',
      //         name: 'ALICIA VERONICA MODOK',
      //       },
      //     ],
      //     detail: [
      //       {
      //         vusn: 'ferdinanda.raubun@sekolahmakarios.id',
      //         linkStudent: 'ALICIA VERONICA MODOK',
      //         role: 'Ibu',
      //       },
      //     ],
      //   },
      // };

      const role = response.role;
      const studentRole = role.status[0].ROLE;
      const detail_role = role.detail_role[0];
      let checkDetailRole =
        detail_role && detail_role.ISI && detail_role.ISI === 'Tidak Ada Role';
      let detailRole = [];
      if (!checkDetailRole) {
        detailRole = role.detail_role;
      }
      let checkGroupSibling = role && role.group;
      let groupSibling = [];
      if (checkGroupSibling) {
        groupSibling = [];
      }
      let userRole = {
        role: studentRole,
        group: groupSibling,
        detail: detailRole,
      };
      dispatch(authroleGet(userRole));
      dispatch(asyncActionFinish());
    } catch (err) {
      checkErr(err);
      dispatch(asyncActionError());
    }
  };
};

export const registerUserRole = (auth, history, values) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: 'updateAuth' });
    const vusn = auth.vusn;
    const userId = auth.userId;
    const sessionId = auth.sessionId ? auth.sessionId : '';
    const deviceId = auth.deviceId ? auth.deviceId : '';
    const vusnreg = values.vusnreg;
    const passwordreg = values.passwordreg;
    const rolereg = values.rolereg;
    const formData = new FormData();
    formData.append('vusn', vusn);
    formData.append('userId', userId);
    formData.append('sessionId', sessionId);
    formData.append('deviceId', deviceId);
    formData.append('vusnreg', vusnreg);
    formData.append('pwdreg', passwordreg);
    formData.append('rolereg', rolereg);
    try {
      let fetchData = await fetch(SITE_ADDRESS + 'auth/role-register', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + auth.token,
        },
      });
      const response = await fetchData.json();
      const resultCheck = checkRes(response, fetchData, dispatch, 200, history);
      if (resultCheck) {
        throw resultCheck;
      }
      history.push('/register-3');
      dispatch(closeModal());
      dispatch(asyncActionFinish());
    } catch (err) {
      toastr.error('Error', err.message);
      dispatch(asyncActionError());
    }
  };
};

export const sessionLogout = (elementName, history) => {
  return async (dispatch) => {
    dispatch({ type: ASYNC_ACTION_START, payload: elementName });
    try {
      history.push('/');
      dispatch(authLogout());
      dispatch(closeModal());
      dispatch(asyncActionFinish());
    } catch (err) {
      console.log(err);
      toastr.error('Error', err.message);
      dispatch(asyncActionError());
    }
  };
};
