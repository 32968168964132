import { RAPORKETUNTASAN_GET, RAPORKETUNTASANDETAIL_GET } from './reduxConstant';
import { createReducer } from '../../../../App/common/utilities/reducerUtils';

const initialState = { raporketuntasan: [] };

const raporketuntasanGet = (state, payload) => {
  let raporketuntasan = payload.raporketuntasan;
  return { ...state, raporketuntasan: [...raporketuntasan] };
};

const raporketuntasanDetailGet = (state, payload) => {
  let raporketuntasanDetail = {
    ...state.raporketuntasan.filter(
      (item) => item.IDXRAPOR === payload.raporketuntasanDetail.id
    )[0],
    ...payload.raporketuntasanDetail,
  };
  return {
    ...state,
    raporketuntasan: [
      ...state.raporketuntasan.filter(
        (item) => item.IDXRAPOR !== payload.raporketuntasanDetail.id
      ),
      raporketuntasanDetail,
    ],
  };
};

export default createReducer(initialState, {
  [RAPORKETUNTASAN_GET]: raporketuntasanGet,
  [RAPORKETUNTASANDETAIL_GET]: raporketuntasanDetailGet,
});
