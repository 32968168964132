import React, { useEffect } from 'react';

export default function MessageBadge({ unreadPesan, deviceId }) {
  useEffect(() => {
    console.log(unreadPesan && unreadPesan.length < 1);
    const bottomNotif = document.querySelector('#bottomNavNotif');
    if (deviceId) {
      if (unreadPesan && unreadPesan.length < 1) {
        if (bottomNotif) {
          bottomNotif.style.display = 'none';
        }
      } else {
        if (bottomNotif) {
          bottomNotif.style.display = 'block';
        }
      }
    }
  }, [deviceId, unreadPesan]);

  return (
    <span
      id='bottomNavNotif'
      className='is-overlay'
      style={{ display: 'none' }}
    >
      <i
        className='mdi mdi-circle-medium icon has-text-danger ml-3'
        style={{ fontSize: 18 }}
      />
    </span>
  );
}
